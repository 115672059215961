"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.incrementCounter = exports.shuffleField = exports.checkWin = exports.firstZeroConcat = exports.isValidForSwap = exports.findCoordByNumber = exports.getMatrix = void 0;
const DOM_1 = require("./DOM");
const localStorage_1 = require("./localStorage");
const getMatrix = (arr, size) => {
    const matrix = [];
    arr.map(() => matrix.push(arr.splice(0, size)));
    return matrix;
};
exports.getMatrix = getMatrix;
const findCoordByNumber = (num, matrix) => {
    for (let y = 0; y < matrix.length; y++) {
        for (let x = 0; x < matrix.length; x++) {
            if (matrix[y][x] === num) {
                return { x, y };
            }
        }
    }
    return undefined;
};
exports.findCoordByNumber = findCoordByNumber;
const isValidForSwap = (coords1, coords2) => {
    const diffX = Math.abs(coords1.x - coords2.x);
    const diffY = Math.abs(coords1.y - coords2.y);
    if ((diffX === 1 || diffY === 1) && (coords1.x === coords2.x || coords1.y === coords2.y)) {
        return 'short-swap';
    }
    if (((diffX === 0 && diffY !== 0) || (diffX !== 0 && diffY === 0))) {
        return 'long-swap';
    }
    return false;
};
exports.isValidForSwap = isValidForSwap;
const firstZeroConcat = (row, blank, end) => [].concat(blank, row.slice(0, end));
exports.firstZeroConcat = firstZeroConcat;
const checkWin = (matrix, matrixWin) => {
    if (JSON.stringify(matrix) === JSON.stringify(matrixWin)) {
        alert('победа');
    }
};
exports.checkWin = checkWin;
var canBoardWin = (array, G) => {
    // Check if Start board is the same after ramdomize
    // let startBoardPosition = array.every((el, i) => {
    //   return el === solved_puzzle[i];
    // });
    // if (startBoardPosition) return false;
    // Check can board win
    let p = 0;
    let row = 0;
    let blankRow = 0;
    for (let i = 0; i < array.length; i++) {
        if (i % G == 0)
            row++;
        if (array[i] == 0) {
            blankRow = row;
            continue;
        }
        for (let j = i + 1; j < array.length; j++) {
            if (array[i] > array[j] && array[j] != 0)
                p++;
        }
    }
    if (G % 2 == 0 && blankRow % 2 != 0)
        return p % 2 != 0;
    else
        return p % 2 == 0;
};
const shuffleField = (fields, size) => {
    let arr = [...fields].sort(() => Math.random() - .5).concat(0);
    if (canBoardWin(arr, size)) {
        return arr;
    }
    return (0, exports.shuffleField)(fields, size);
};
exports.shuffleField = shuffleField;
const incrementCounter = () => {
    let counter = Number(DOM_1.fifteenCounter.innerHTML);
    DOM_1.fifteenCounter.innerHTML = String(++counter);
    (0, localStorage_1.updateLocalCounter)(counter);
};
exports.incrementCounter = incrementCounter;
