"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.updateLocalCounter = exports.updateLocalStorage = exports.counterStorage = exports.fifteenStorage = exports.matrixStorage = exports.sizeStorage = void 0;
exports.sizeStorage = localStorage.getItem('15puzzle__size');
exports.matrixStorage = localStorage.getItem('15puzzle__matrix');
exports.fifteenStorage = localStorage.getItem('15puzzle__fifteen');
const counterStorage = () => JSON.parse(localStorage.getItem('15puzzle__counter'));
exports.counterStorage = counterStorage;
const updateLocalStorage = (fifteen) => localStorage.setItem('15puzzle__fifteen', JSON.stringify(fifteen));
exports.updateLocalStorage = updateLocalStorage;
const updateLocalCounter = (counter) => localStorage.setItem('15puzzle__counter', JSON.stringify(counter));
exports.updateLocalCounter = updateLocalCounter;
